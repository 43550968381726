import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration, Redirect } from 'aurelia-router';
import {AuthorizeStep} from '@clevermind/tinty2-webapp-core/views/app';
import { default as AppLarioLibri } from '@clevermind/webapp-lariolibri/app';
import {default as UserLario} from '@clevermind/webapp-lariolibri/lib/User';
import { default as GDO } from '@clevermind/webapp-lariolibri/lib/resources/GDO';
import { default as Locales } from './locales';
import {default as Constants} from './lib/Constants';
import {castArray, compact, trimStart, startsWith} from 'lodash';
// import the main scss like this, in order to prevent the page being rendered before the styles are applied!
import './app.scss';

export class App extends AppLarioLibri {
  currentGDO = '';
  enableSignUp = false;
  gdo = null;
  constructor(...aArgs: any[]) {
    super(...aArgs);
    this.root = 'lariolibri';
    this.locales = Locales;
    // the authorize step will be handled in the child router
    const _authorizationStep: any = LarioLibriGDOStep;
    this.authorizationSteps = [_authorizationStep]; // Init authorization steps
  }

  async activate(): Promise<any> {
    // Initializing Store
    await super.activate();
    // force language
    await this.setLanguage('it');
  }

  navigateToProfile() {
    this.navigate(this.currentGDO + '/profile');
  }

  /** @override */
  public async getMe(criteria?: {licenses: boolean}): Promise<UserLario> {
    const user = await super.getMe(criteria);
    if ((this.customer && this.customer.gdo_id) || user?.gdo_id) {
      // load my gdo
      let URL = await this.addServiceBaseURL('resource/gdo', {});
      const _userGDOIDs = compact(castArray(user?.gdo_id));
      const [_gdo] = await this.handleRequest('GET', URL, null, {where: {id: this.customer?.gdo_id || {in: _userGDOIDs}}}, {});
      this.gdo = new GDO(_gdo);
    }
    return user;
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    config.options.pushState = true;
    config.options.root = '/';
    router.transformTitle = title => this.i18n.tr(title);
    this.eventAggregator.subscribe('i18n:locale:changed', () => {
      router.updateTitle();
    });
    //if no route was found, fallback to "not-found" route
    config.fallbackRoute('error/404');
    this.authorizationSteps.forEach((authorizationStep) => {
      config.addPipelineStep('authorize', authorizationStep);
    });
    // override the layout
    config.map([{
      route: ['', 'homepage'],
      name: 'homepage',
      moduleId: PLATFORM.moduleName('lariolibri/views/homepage'),
      nav: false,
      disableAuth: true,
      title: 'Page.Homepage.Label',
      icon: 'fal fa-home'
    }, {
      route: ['coop'],
      name: 'coop',
      nav: false,
      disableAuth: true,
      title: 'GDO.Coop.Label',
      navigationStrategy: (navigationInstruction) => {
        this.currentGDO = Constants._GDO_CODE_COOP;
        this.verticalization = 'lariolibri-' + this.currentGDO;
        this.defaultSignInRoute = 'coop/homepage';
        navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/gdo/app');
      }
    }, {
      route: ['bennet'],
      name: 'bennet',
      nav: false,
      disableAuth: true,
      title: 'GDO.Bennet.Label',
      navigationStrategy: (navigationInstruction) => {
        this.currentGDO = Constants._GDO_CODE_BENNET;
        this.verticalization = 'lariolibri-' + this.currentGDO;
        this.defaultSignInRoute = 'bennet/homepage';
        navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/gdo/app');
      }
    }, {
      route: ['conad'],
      name: 'conad',
      nav: false,
      disableAuth: true,
      title: 'GDO.Conad.Label',
      navigationStrategy: (navigationInstruction) => {
        this.currentGDO = Constants._GDO_CODE_CONAD;
        this.verticalization = 'lariolibri-' + this.currentGDO;
        this.defaultSignInRoute = 'conad/homepage';
        navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/gdo/app');
      }
    }, {
      route: ['pac2000'],
      name: 'pac2000',
      nav: false,
      disableAuth: true,
      title: 'GDO.Pac2000.Label',
      navigationStrategy: (navigationInstruction) => {
        this.currentGDO = Constants._GDO_CODE_PAC2000;
        this.verticalization = 'lariolibri-' + this.currentGDO;
        this.defaultSignInRoute = 'pac2000/homepage';
        navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/gdo/app');
      }
    }, {
      route: ['centercasa'],
      name: 'centercasa',
      nav: false,
      disableAuth: true,
      title: 'GDO.Centercasa.Label',
      navigationStrategy: (navigationInstruction) => {
        this.currentGDO = Constants._GDO_CODE_CENTERCASA;
        this.verticalization = 'lariolibri-' + this.currentGDO;
        this.defaultSignInRoute = 'centercasa/homepage';
        navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/gdo/app');
      }
    }, {
      route: ['maxfactory'],
      name: 'maxfactory',
      nav: false,
      disableAuth: true,
      title: 'GDO.Maxfactory.Label',
      navigationStrategy: (navigationInstruction) => {
        this.currentGDO = Constants._GDO_CODE_MAXFACTORY;
        this.verticalization = 'lariolibri-' + this.currentGDO;
        this.defaultSignInRoute = 'maxfactory/homepage';
        navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/gdo/app');
      }
    }, {
      route: ['error', 'error/:code'],
      name: 'error',
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/error'),
      nav: false,
      title: 'Page.Error.Label',
      disableAuth: true
    }, {
      route: 'signin',
      name: 'signin',
      disableAuth: true,
      navigationStrategy: (navigationInstruction) => {
        if(this.currentGDO) {
          navigationInstruction.config.redirect = this.currentGDO + '/signin';
        } else {
          navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/users/signin');
        }
      }
    }, {
      route: ['signup-customer/:sale_point?'],
      name: 'signup-customer',
      navigationStrategy: (navigationInstruction) => {
        if(this.currentGDO) {
          navigationInstruction.config.redirect = this.currentGDO + '/signup-customer';
        } else {
          navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/users/signup-customer');
        }
      },
      nav: false,
      title: 'Page.SignUpCustomer.Label',
      disableAuth: true
    }, {
      route: ['forgot-password'],
      name: 'forgot-password',
      navigationStrategy: (navigationInstruction) => {
        if(this.currentGDO) {
          navigationInstruction.config.redirect = this.currentGDO + '/forgot-password';
        } else {
          navigationInstruction.config.moduleId = PLATFORM.moduleName('lariolibri/views/users/password-forgot');
        }
      },
      nav: false,
      title: 'Page.SignUser.ForgotPassword',
      disableAuth: true
    }, {
      route: ['change-password'],
      name: 'change-password',
      navigationStrategy: (navigationInstruction) => {
        if(this.currentGDO) {
          navigationInstruction.config.redirect = this.currentGDO + '/change-password';
        } else {
          navigationInstruction.config.moduleId = PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/users/password-forced');
        }
      },
      nav: false,
      title: 'Page.SignUser.UpdatePassword',
      disableAuth: true
    }, {
      route: ['enable/:username?/:challenge?', 'reset/:username?/:challenge?'],
      name: 'enable',
      navigationStrategy: (navigationInstruction) => {
        if(this.currentGDO) {
          navigationInstruction.config.redirect = this.currentGDO + '/enable';
        } else {
          navigationInstruction.config.moduleId = PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/users/enable');
        }
      },
      nav: false,
      title: 'Page.SignUser.EnableUser',
      disableAuth: true
    }]);
    // Remembering router
    this.router = router;
  }

  /** @override */
  public navigate(route: string, router = null): void {
    if(this.currentGDO && !startsWith(route, this.currentGDO) && route.indexOf('error') !== -1) {
      return super.navigate(this.currentGDO + '/' + trimStart(route, '#/'), router);
    }
    return super.navigate(route, router);
  }
}

// Needed to be correctly imported by extending apps
class LarioLibriGDOStep extends AuthorizeStep {
  hasLoadedUserData = false;
  async run(navigationInstruction, next) {
    const _webapp = window.webapp;
    if(!this.hasLoadedUserData) {
      if(!_webapp.user) {
        // Tyring to access our profile data
        try {_webapp.user = await _webapp.getMe();} catch(e) {}
      }
      // Checking if we are authenticated (if not and if possible, will also try to refresh the tokens to ensure we are really not authenticated)
      if (await _webapp.auth.isAuthenticated()) {
        // ensure to set the user after the refresh
        if (!_webapp.user) {
          _webapp.user = await _webapp.getMe();
        }
      }
      this.hasLoadedUserData = true;
    }
    const isAuthenticated = await _webapp.auth.isAuthenticated();
    const gdoID = _webapp.customer?.gdo_id || _webapp.user?.gdo_id;
    if (
      isAuthenticated &&
      _webapp.user &&
      !_webapp.user.hasRole(Constants._USER_ROLE_ADMINISTRATOR) &&
      ((!_webapp.user.hasRole(Constants._USER_ROLE_GDO_RESPONSIBLE) && !(_webapp.user.isUser() && gdoID)) || !gdoID || _webapp.gdo?.code !== _webapp.currentGDO) &&
      (startsWith(navigationInstruction.config.route, _webapp.currentGDO))) {
      _webapp.debug( '[Login Check] %o with roles %o is not authorized to access to Lario Libri GDO %s...', _webapp.user.username, _webapp.user.roles, navigationInstruction.config.route );
      _webapp.signOutOnUnAuthorizedRouteAccess = true;
      // Redirecting to login ( the login will return the user to the previous wanted view )
      return next.cancel(new Redirect(_webapp.gdo?.code + '/signin'));
    }
    return super.run(navigationInstruction, next);
  }
}