import {Aurelia, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {TCustomAttribute} from 'aurelia-i18n';
import LanguageDetector from 'i18next-browser-languagedetector'; // https://github.com/i18next/i18next-browser-languageDetector
import {configurePlugin} from '@clevermind/tinty2-webapp-core/lib/Auth.OAUTH2'; // Configuring plugins needed by OAUTH2 authentication
import {config as BluebirdConfig} from 'bluebird';
import {default as environment} from '../config/environment.json';
window.environment = environment; // Sharing environment with window

PLATFORM.moduleName('aurelia-authentication/authFilterValueConverter'); // Needed to correctly find module
export function configure(aurelia: Aurelia):void {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/attributes/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/converters/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/components/carousel/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/components/core/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/components/core/files/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/components/pwa/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/components/lazy-load/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-core/components/QR/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/attributes/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/2fa/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/file-download/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/forms/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/modals/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/inputs/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/layout/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/modals/index'))
    .feature(PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/components/privacy/index'))
    .feature(PLATFORM.moduleName('@clevermind/webapp-lariolibri/components/index'))
    .feature(PLATFORM.moduleName('@clevermind/webapp-lariolibri/converters/index'))
    .feature(PLATFORM.moduleName('lariolibri/components/index'))
    .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
      const _aAliases = ['t', 'i18n'];
      // add aliases for 't' attribute (otherwise it wont work with components loaded by "feature")
      TCustomAttribute.configureAliases( _aAliases );
      instance.i18next.use(LanguageDetector);
      return instance.setup({
        resources: {},
        attributes: _aAliases,
        debug: (environment.debug ? true : false)
      });
    }) // Initializing aurelia i18n plugin
    .plugin(PLATFORM.moduleName('aurelia-validation')) // Initializing aurelia validation plugin
    .plugin(PLATFORM.moduleName('aurelia-api'), config => configurePlugin('aurelia-api', config, environment))
    .plugin(PLATFORM.moduleName('aurelia-authentication'), config => configurePlugin('aurelia-authentication', config, environment))
  ;
  // Setting log level by environment
  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');
  BluebirdConfig({ warnings: false});
  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  }

  const logger = LogManager.getLogger('app');
  aurelia.start()
  .then(() => {
    aurelia.setRoot(PLATFORM.moduleName('lariolibri/app'));
    if (!environment.debug && environment.google?.gtag) {
      // add inside head
      $('head').prepend('<!-- Google Tag Manager -->\
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\
        new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\
        j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\
        \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\
        })(window,document,\'script\',\'dataLayer\',\'' + environment.google?.gtag + '\');</script>\
        <!-- End Google Tag Manager -->');
      // add inside body
      $('body').prepend('<!-- Google Tag Manager (noscript) -->\
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=' + environment.google?.gtag + '" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>\
      <!-- End Google Tag Manager (noscript) -->');
    }
  })
    .catch((error) => {
      logger.error('Failed to launch Aurelia App.', error);
      $('.launcher__waiting').hide();
      $('.aurelia-splash-container').addClass('shown');
    })
  ;
}
