import {default as CoreView} from '@clevermind/tinty2-webapp-core/model/View';
import { PLATFORM } from 'aurelia-pal';
import {Router, RouterConfiguration} from 'aurelia-router';
import {default as Constants} from '../../lib/Constants';
import {compact} from 'lodash';

export default class Homepage extends CoreView {
  router: Router = null;

  public configureRouter(config: RouterConfiguration, router: Router) {
    config.options.pushState = true;
    // override the layout
    config.map(compact([{
      route: ['homepage'],
      name: 'homepage',
      moduleId: PLATFORM.moduleName('lariolibri/views/gdo/homepage'),
      nav: true,
      title: 'Page.ReservedArea.Label',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      icon: 'fal fa-home'
    }, {
      route: ['products/:filter?'],
      name: 'products',
      href: 'products',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/search-products/products'),
      nav: true,
      title: this.webapp.currentGDO === Constants._GDO_CODE_MAXFACTORY ?  'Page.Products.LabelExtended' : 'Page.Products.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.SearchProducts',
        icon: 'fal fa-search'
      }
    }, {
      route: ['adoptions'],
      name: 'adoptions',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/search-products/adoptions'),
      nav: true,
      title: this.webapp.currentGDO === Constants._GDO_CODE_MAXFACTORY ?  'Page.Adoptions.LabelExtended' : 'Page.Adoptions.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.SearchProducts',
        icon: 'fal fa-search'
      }
    }, {
      route: ['cart'],
      name: 'cart',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/cart'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.Cart.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['orders'],
      name: 'orders',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/orders'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.Orders.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_ADMINISTRATOR, Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['orders-exploded/:customer_id?'],
      name: 'orders-exploded',
      href: 'orders-exploded',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/orders-exploded'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.OrdersExploded.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_ADMINISTRATOR, Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['products-to-confirm'],
      name: 'products-to-confirm',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/users/products-to-confirm'),
      nav: true,
      icon: 'fal fa-calendar-clock',
      title: 'Page.ProductsToConfirm.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['cancel-requests'],
      name: 'cancel-requests',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/cancel-requests'),
      nav: true,
      title: 'Page.CancelRequests.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_ADMINISTRATOR, Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['order-detail/:id'],
      name: 'order-detail',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/order-detail'),
      nav: false,
      title: 'Page.OrderDetails.Label',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['customers/:disabled?'],
      name: 'customers',
      href: 'customers',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/customers'),
      nav: true,
      icon: 'fal fa-users',
      title: 'Page.Customers.Label',
      userRoles: [Constants._USER_ROLE_GDO_RESPONSIBLE],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['receiving'],
      name: 'receiving',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/receiving'),
      nav: true,
      icon: 'fal fa-hand-holding-box',
      title: 'Page.Receiving.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_ADMINISTRATOR, Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Documents',
        icon: 'fal fa-hand-holding-box'
      }
    }, {
      route: ['deliveries'],
      name: 'deliveries',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/deliveries'),
      nav: true,
      icon: 'fal fa-person-carry-box',
      title: 'Page.Deliveries.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_ADMINISTRATOR, Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Documents',
        icon: 'fal fa-hand-holding-box'
      }
    }, {
      route: ['return-lists'],
      name: 'return-lists',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/return-lists'),
      nav: true,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnLists.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Documents',
        icon: 'fal fa-hand-holding-box'
      }
    }, {
      route: ['form-return-lists/:id'],
      name: 'form-return-lists',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/form-return-lists'),
      nav: false,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnLists.Label',
      userRoles: [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['customers-trashbin'],
      name: 'customers-trashbin',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/rejected-products/customers-trashbin'),
      nav: true,
      title: 'Page.CustomersTrashbin.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_ADMINISTRATOR, Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Page.RejectedProducts.Label',
        icon: 'fal fa-boxes-stacked'
      }
    }, {
      route: ['rejected-products'],
      name: 'rejected-products',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/rejected-products/rejected-products'),
      nav: true,
      title: 'Page.LocalStock.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_GDO_ADMINISTRATOR, Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_GDO_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Page.RejectedProducts.Label',
        icon: 'fal fa-boxes-stacked'
      }
    }, {
      route: ['assistance'],
      name: 'assistance',
      href: 'assistance',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/assistance'),
      nav: true,
      icon: 'fal fa-headphones',
      title: 'Page.Assistance.Label',
      additionalFilter: () => {
        return !this.webapp.user?.isUser() || this.webapp.currentGDO === Constants._GDO_CODE_CONAD || this.webapp.currentGDO === Constants._GDO_CODE_PAC2000 ? true : false
      },
      // userRoles: [Constants._USER_ROLE_GDO_RESPONSIBLE],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['signup-customer/:sale_point?'],
      name: 'signup-customer',
      moduleId: PLATFORM.moduleName('lariolibri/views/users/signup-customer'),
      nav: false,
      title: 'Page.SignUpCustomer.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/gdo')
    }, {
      route: ['', 'signin/:sale_point?'],
      name: 'signin',
      moduleId: PLATFORM.moduleName('lariolibri/views/users/signin'),
      nav: false,
      title: 'Page.SignIn.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/gdo')
    }, {
      route: ['forgot-password'],
      name: 'forgot-password',
      moduleId: PLATFORM.moduleName('lariolibri/views/users/password-forgot'),
      nav: false,
      title: 'Page.SignUser.ForgotPassword',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/gdo')
    }, {
      route: ['change-password'],
      name: 'change-password',
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/users/password-forced'),
      nav: false,
      title: 'Page.SignUser.UpdatePassword',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/gdo')
    }, {
      route: ['enable/:username?/:challenge?', 'reset/:username?/:challenge?'],
      name: 'enable',
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/users/enable'),
      nav: false,
      title: 'Page.SignUser.EnableUser',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/gdo')
    }, (this.webapp.currentGDO === 'coop' ? {
      route: ['covers'],
      name: 'covers',
      moduleId: PLATFORM.moduleName('lariolibri/views/gdo/covers'),
      nav: false,
      title: 'Page.Covers.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/gdo')
    } : null), {
      route: ['profile'],
      name: 'profile',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/users/profile'),
      nav: false,
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['error', 'error/:code'],
      name: 'error',
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/error'),
      nav: false,
      title: 'Page.Error.Label',
      disableAuth: true
    }, {
      route: 'documents-details/:token',
      name: 'documents-details',
      moduleId: PLATFORM.moduleName('lariolibri/views/gdo/customer-documents-details'),
      nav: false,
      title: 'Page.CustomerDocumentsDetails.Label',
      disableAuth: true
    }]))
    this.router = router;
  }
}