import {default as GDO} from '@clevermind/webapp-lariolibri/lib/resources/GDO';
import { default as BootstrapSignIn } from '@clevermind/tinty2-webapp-bootstrap/views/users/signin';
import {default as Constants} from '../../lib/Constants';
import {upperFirst} from 'lodash';

export default class SignIn extends BootstrapSignIn {
  parseParams = false;
  carouselImages = ['assets/images/slide-1.png', 'assets/images/slide-2.png', 'assets/images/slide-3.png', 'assets/images/slide-4.png', 'assets/images/slide-5.png', 'assets/images/slide-2.png'];
  gdo:GDO = null;
  salePointAddress = null;
  salePoint = null;
  privacy_accepted = false;
  rememberme = true;

  async __activate(params: {cancel_url?: string, state?: string, sale_point?: string, PV?: string, anacan?: string}): Promise<any> {
    const salePoint = params?.sale_point || params?.PV || params?.anacan;
    if (salePoint) {
      try {
        const response = await this.postAPI('validate-registration-sale-point', {sale_point: salePoint}, {redirectToErrorPage: false});
        if (response?.gdo?.code === this.webapp.currentGDO) {
          this.salePoint = response?.sale_point;
          this.salePointAddress = response?.address;
          this.gdo = new GDO(response?.gdo);
        } else if (response?.gdo?.code === Constants._GDO_CODE_PAC2000 && this.webapp.currentGDO === Constants._GDO_CODE_CONAD) {
          this.webapp.navigate(`${Constants._GDO_CODE_PAC2000}/signin?anacan=${salePoint}`);
        }
      } catch(_error) {
        // error handled in interface
      }
    }
    const gdoCode = this.gdo?.code || this.webapp.currentGDO;
    if(gdoCode !== Constants._GDO_CODE_COOP) {
      this.carouselImages[4] =  'assets/images/slide-5-blu.png';
    }
    if(gdoCode === Constants._GDO_CODE_CONAD || gdoCode === Constants._GDO_CODE_PAC2000) {
      this.carouselImages[0] =  'assets/images/slide-1_conad.jpg';
    }
    if(gdoCode === Constants._GDO_CODE_MAXFACTORY) {
      this.carouselImages = [
        'brands/maxfactory/images/slide-1.png',
        'brands/maxfactory/images/slide-2.png',
        'brands/maxfactory/images/slide-3.png',
        'brands/maxfactory/images/slide-4.png',
        'brands/maxfactory/images/slide-5.jpg',
        'brands/maxfactory/images/slide-6.png'
      ];
    }
    super.__activate(params);
  }

  getGDOLocale() {
    return upperFirst(this.gdo?.code || this.webapp.currentGDO);
  }

  // Forcing replace
  determineActivationStrategy(): string {
    return 'replace';
  }
}
