import {default as DataTable} from '@clevermind/tinty2-webapp-bootstrap/components/tables/datatable-net';
import {ColumnModel, SelectMenuColumnModel, DatetimeColumnModel, BadgeColumnModel} from '@clevermind/tinty2-webapp-core/components/tables/datatable';
import {default as DocumentDetail} from '@clevermind/webapp-lariolibri/lib/resources/DocumentDetail';
import {default as Constants} from '../../lib/Constants';
import {default as CoreView} from '@clevermind/tinty2-webapp-core/model/View';
import Customer from '@clevermind/webapp-lariolibri/lib/resources/Customer';
import Destination from '@clevermind/webapp-lariolibri/lib/resources/Destination';
import { findIndex } from 'lodash';

export default class CustomerDocumentsDetails extends CoreView {
  hasError: boolean = false;
  dataTable: DataTable;
  sortColumn = 0;
  columns: Partial<ColumnModel | SelectMenuColumnModel | DatetimeColumnModel | BadgeColumnModel>[] = [{
    key: 'order_id',
    label: 'Order.ID'
  }, {
    key: 'order_datetime',
    label: 'Order.Date',
    type: Constants._DATATABLE_DATE_COLUMN,
  }, {
    key: 'product_code',
    label: 'Generic.EAN'
  }, {
    key: 'product_title',
    label: 'Generic.Title'
  }, {
    key: 'order_cover',
    label: 'Generic.Cover',
    class: 'colibri-cover',
    type: Constants._DATATABLE_SWITCH_COLUMN
  }, {
    key: 'quantity',
    label: 'Generic.Quantity',
    type: Constants._DATATABLE_NUMBER_COLUMN
  }, {
    key: 'price',
    label: 'Generic.Price',
    type: Constants._DATATABLE_NUMBER_COLUMN,
    converters: ['currency']
  }];
  dataSource: DocumentDetail[] = [];
  customer: Customer;
  destination: Destination;
  token: string;

  async __activate(params: {token: string}, ...aArgs: any[]): Promise<any> {
    this.token = params.token;
    this.sortColumn = findIndex(this.columns, {key: 'order_id'});
    await this.loadData();
  }

  async loadData() {
    try {
      const result = await this.postAPI('validate-documents-details-token', {gdo: this.webapp.currentGDO, token: this.token}, {redirectToErrorPage: false});
      if (result) {
        this.dataSource = result.documents_details;
        this.customer = result.customer;
        this.destination = result.destination;
      }
    } catch (error) {
      this.hasError = true;
      this.webapp.navigate('error/404');
    }
  }

  isSelectableRow(row: DocumentDetail): boolean {
    return true;
  }

  reject() {
    const selectedIDs = this.dataTable.getSelectedRowIDs();
    if(!selectedIDs.length) {
      return this.showModal({
        type: 'warning',
        title: 'Generic.Warning',
        body: 'Page.ProductsToConfirm.NoDetailsSelected'
      });
    }
    this.showConfirm({
      operation: async () => {
        try {
          await this.postAPI('reject-documents-details-by-token', {
            documents_details_ids: selectedIDs,
            token: this.token,
            gdo: this.webapp.currentGDO
          }, {redirectToErrorPage: false});
          this.showModal({
            body: 'Components.ProductsToConfirm.Success.Body',
            title: 'Components.ProductsToConfirm.Success.Title'
          });
          await this.loadData();
        } catch(_error) {
          this.showModal({
            type: 'error',
            title: 'Generic.Error',
            body: 'Page.CustomerDocumentsDetails.ErrorSubmit'
          });
        }
      }
    });
  }
}