import { default as GDO } from '@clevermind/webapp-lariolibri/lib/resources/GDO';
import { default as CoreView } from '@clevermind/tinty2-webapp-core/model/View';
import { default as Constants } from '../../lib/Constants';
import { CurrencyValueConverter } from '@clevermind/tinty2-webapp-core/converters/currency';
import { upperFirst } from 'lodash';

export default class SignUpCustomer extends CoreView {
  gdo: GDO = null;
  salePointAddress = null;
  salePoint = null;
  user = { challenge_redirect: '', name: '', surname: '', email: '', mobile_phone: '', password: '', sale_point: '', data: {} };
  privacy_accepted = false;
  privacy_third_party = false;
  showPrivacyPDF = true;
  showThirdPartyConsent = false;
  coopPrivacyRegion;


  async __activate(params: { sale_point?: string, PV?: string, anacan?: string }, ...aArgs: any[]): Promise<any> {
    const salePoint = params?.sale_point || params?.PV || params?.anacan;
    if (salePoint) {
      try {
        const response = await this.postAPI('validate-registration-sale-point', { sale_point: salePoint }, { redirectToErrorPage: false });
        if (response?.gdo?.code === this.webapp.currentGDO) {
          this.salePoint = response?.sale_point;
          this.salePointAddress = response?.address;
          this.coopPrivacyRegion = response?.gdo?.region;
          this.gdo = new GDO(response?.gdo);
          this.user.sale_point = salePoint;
        }
        // we do not have a PDF...
        if (this.gdo.isCoop && this.coopPrivacyRegion.toLowerCase() === Constants._GDO_COOP_REGION_LIGURIA) {
          this.showPrivacyPDF = false;
        }

        if (this.gdo.isMaxfactory) {
          this.showThirdPartyConsent = true;
        }
      } catch (_error) {
        // error handled in interface
      }
    }
    super.__activate(params, ...aArgs);
  }

  getLocalizeParameters() {
    return {
      price: this.getGDOCoverPrice(),
      end_date: this.getGDOEndCampaignDate()
    };
  }

  getGDOLocale() {
    return upperFirst(this.gdo?.code || this.webapp.currentGDO);
  }

  getGDOCoverPrice() {
    return new CurrencyValueConverter().toView(this.gdo?.cover_price || 0);
  }


  getGDOEndCampaignDate() {
    switch (this.gdo?.code) {
      case Constants._GDO_CODE_COOP:
        if (this.coopPrivacyRegion.toLowerCase() === Constants._GDO_COOP_REGION_LIGURIA) {
          return '13/10/2024';
        }
        if (this.coopPrivacyRegion.toLowerCase() === Constants._GDO_COOP_REGION_LOMBARDIA) {
          return '26/10/2024';
        }
        return '17/11/2024';
      case Constants._GDO_CODE_CONAD:
        return '30/09/2024';
      case Constants._GDO_CODE_BENNET:
        return '30/09/2024';
      case Constants._GDO_CODE_MAXFACTORY:
        return '31/10/2024';
      case Constants._GDO_CODE_PAC2000:
        return '30/09/2024';
    }
    return '';
  }

  async signUp() {
    await this.isValid();
    this.showConfirm({
      operation: async () => {
        this.user.challenge_redirect = 'signin/' + this.user.sale_point;
        this.user.data = { third_party_consent: this.privacy_third_party };
        await this.postAPI('shop-customer', this.user, { redirectToErrorPage: false });
        await this.showModal({ icon: 'fad fa-mailbox text-primary', title: 'Page.SignUser.CheckMailBox', body: 'Page.SignUser.RegisteredInstructions' });
        this.navigate('signin');
      }
    });
  }

}